<template>
  <div id="mian">
    <div class="desc">
      <h5 class="jsxx">微信特约配置</h5>
      <div>
        <el-button @click="setTradeSync" class="reEmailBt" type="primary"
          >同步</el-button
        >
        <el-button @click="fromShow" class="editBt" type="primary"
          >录入</el-button
        >
      </div>
    </div>
    <div class="list clear">
      <ul class="listBox">
        <li>
          <span>店铺名称</span>
          <i class="hidden1">{{ detail.storeName }}</i>
        </li>
        <li>
          <span>店铺ID</span>
          <i class="hidden1">{{ detail.storeId }}</i>
        </li>
        <li>
          <span>所属商户</span>
          <i class="hidden1">{{ detail.mchName }}</i>
        </li>
        <li>
          <span>报备商户号</span>
          <i class="hidden1">{{ detail.tradeMchNo }}</i>
        </li>
        <li>
          <span>关联APPID</span>
          <i class="hidden1">
            <el-tooltip
              class="item"
              effect="light"
              :content="detail.subAppId"
              placement="top"
            >
              <span>{{ detail.subAppId }}</span>
            </el-tooltip>
          </i>
        </li>
        <li>
          <span>授权目录</span>
          <i class="hidden1">
            <el-tooltip
              class="item"
              effect="light"
              :content="detail.jsapiPath"
              placement="top"
            >
              <span>{{ detail.jsapiPath }}</span>
            </el-tooltip>
          </i>
        </li>
        <li>
          <span>推荐关注APPID</span>
          <i class="hidden1">{{ detail.subscribeAppId }}</i>
        </li>
        <li>
          <span>支付凭证推荐小程序APPID</span>
          <i class="hidden1">{{ detail.receiptAppId }}</i>
        </li>
      </ul>
    </div>
    <div class="btn">
      <el-button @click="back" class="back">返回</el-button>
      <el-button @click="next" class="next" type="primary">确定</el-button>
    </div>
    <el-dialog title="微信特约配置" :visible.sync="newShopShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">店铺名称</div>
          <div class="hidden1">{{ detail.storeName }}</div>
          <div class="overAccount_ul_title">所属商户</div>
          <div class="hidden1">{{ detail.mchName }}</div>
          <div class="overAccount_ul_title">关联APPID</div>
          <el-input
            v-model="fromData.subAppId"
            maxlength="128"
            placeholder="输入关联APPID"
          ></el-input>
          <!-- <div class="overAccount_ul_title">推荐关注APPID</div>
          <el-input
            v-model="fromData.subscribeAppId"
            maxlength="128"
            placeholder="输入推荐关注APPID"
          ></el-input> -->
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title">店铺ID</div>
          <div class="hidden1">{{ detail.storeId }}</div>
          <div class="overAccount_ul_title">报备商户号</div>
          <div class="hidden1">{{ detail.tradeMchNo }}</div>
          <div class="overAccount_ul_title">授权目录</div>
          <el-input
            v-model="fromData.jsapiPath"
            maxlength="128"
            placeholder="输入授权目录"
          ></el-input>
          <!-- <div class="overAccount_ul_title">支付凭证推荐小程序APPID</div>
          <el-input
            v-model="fromData.receiptAppId"
            maxlength="128"
            placeholder="输入支付凭证推荐小程序APPID"
          ></el-input> -->
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="newShopShow = false">取 消</el-button>
        <el-button class="addBt" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getTradeWx, setTradeWx, setTradeSync } from "@/api/user/shopList.js";
export default {
  data() {
    return {
      newShopShow: false, //新增商户信息展示
      detail: "",
      fromData: {
        subAppId: "",
        jsapiPath: "",
        subscribeAppId: "",
        receiptAppId: "",
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    //获取详情
    getDetail() {
      getTradeWx(this.$route.query.id, this.$route.query.tradeMchNo).then(
        (res) => {
          if (res.resultStatus) {
            this.detail = res.resultData;
          }
        }
      );
    },
    //录入展示
    fromShow() {
      this.newShopShow = true;
      this.fromData = {
        subAppId: "",
        jsapiPath: "",
        subscribeAppId: "",
        receiptAppId: "",
      };
    },
    //提交
    submit() {
      setTradeWx(
        this.$route.query.id,
        this.$route.query.tradeMchNo,
        this.fromData
      ).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getDetail();
          this.newShopShow = false;
        }
      });
    },
    //同步
    setTradeSync() {
      setTradeSync(this.$route.query.id, this.$route.query.tradeMchNo).then(
        (res) => {
          if (res.resultStatus) {
            this.getDetail();
            this.$message.success("同步成功");
          }
        }
      );
    },
    back() {
      this.$router.go(-1);
    },
    next() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";

.overAccount_ul {
  overflow: hidden;
}
.overAccount_ul .hidden1 {
  height: 19px;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}

.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}
</style>
